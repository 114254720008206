@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
.loading{
  z-index: 1000;
    background-color: #c0c0c0;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 10px;
    border-radius: 10px;
}
.style-unordered-list {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
}
  .google-covert{
        border: 1px solid #ccc;
        box-shadow: -1px 2px 4px #ccc;
        width: 100%; 
}
.location-box {
    height: 40px;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
}
.style-list {
    cursor: pointer;
    list-style: none;
    border-bottom: 1px solid #aaa;
    height: 40px;
    padding-left: 20px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.style-list:hover {
  background-color: #e7e7e7;
}
.current-loc-ico{
    height: 17px;
    padding-right: 10px;

}
.App {
    font-family: sans-serif;
    text-align: center;
  }



    .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    /*left:-100%;*/
    left:10rem; /* 10rem is the min-width of dropdown-menu */
    margin-top:-6px;
   
}


.dropdown-menu {
  
    font-size: 1.3rem;
    
}



body{
    font-family: 'Koh Santepheap', cursive;
}

h1{

    font-size: 30px;
    font-weight: 600;
    line-height: 80px;
}

h3{
    color: #080808;
    font-family: 'Koh Santepheap', cursive;
    font-size: 8.4rem;
    line-height: 1.071;
    max-width: 900px;
    margin-top: 0;
    margin-bottom: .6rem;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

h2{
    font-size: 48px;
    margin-bottom: 30px;
}

h5{
    margin-bottom: 0px;
}

p{
    font-size: 18px;
    color: #999999;
    line-height: 1.8;
    margin-bottom: 0;
}

a:hover{
    text-decoration: none;
}

a i{
    font-size: 22px;
    /* color: #ffffff; */
    color: #000000;
}

section{
    padding: 120px 0;
}

.bg-light-grey{
    background-color: #f5f5f5;
}

.bg-dark{
    background-color: #1a1a1a !important;
}

.topmargin-xs{
    margin-top: 15px !important;
}

.topmargin-sm{
    margin-top: 30px !important;
}

.topmargin-lg{
    margin-top: 60px !important;
}

.topmargin-lg2{
    margin-top: 5px !important;
}

.btn{
    font-size: 14px;
    padding: 15px 26px;
    /* min-width: 160px; */
    border-radius: 2px;
    display: inline-block;
}

.btn-ligth{
    background-color: #ffffff;
    color: #1a1a1a;
    border: 2px solid #ffffff;
}

.hero_btn ion-icon{
    font-size: 14px;
    margin-left: 6px;
}

.btn-dark{
    background-color: #1a1a1a;
    color: #ffffff;
    border: 2px solid #1a1a1a;
}

.btn-transparent{
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;
}

.btn-transparent:hover{
    color: #ffffff;
}

.full-width{
    width: 100%;
}

.form-control{
   
}

.form-control:focus{
    border: 1px solid #1a1a1a;
    outline: none;
    box-shadow: none;
}
.text-dark{
    color: #1a1a1a;
}

.divider{
    border-bottom: 2px solid#D3D3D3;
}



.nav-link{
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
}

.nav-link2{
    color: #ffffff;
}

.nav-link:hover{
    color: #1a1a1a;
}

/* .header {
    background: url(../img/bg-header.jpeg) no-repeat center;
    background-size: cover;
    min-height: 100vh;
  }*/
.header .navbar {
/* background:rgba(0,0,0,0.3) !important  ; */
/* background:rgb(0 0 0 / 82%) !important  ; */
background-color: #00122fe0!important;
font-family: 'Nanum Myeongjo', serif;
  } 

.header .scroll {
background:black !important ;
} 
 
.logo-brand{
    min-width: 100px;
    max-width: 120px;
    background-image: black;
}

.img-perfil{
    width:350px; /* you can use % */
    height: 350px;
}

.sombreado{
    text-shadow: black 0.1em 0.1em 0.2em
}
#hero{
    /* background-image: url('./imagenes/hero_1.jpg'); */
    background-size: cover;
    padding-top: 90px;
    min-height: 700px;
    color: #ffffff;text-shadow: black 0.1em 0.1em 0.2em;



    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


     /* Sizing */
     width: 100vw;
     height: 100vh;
     
     /* Flexbox stuff */
     display: flex;
     justify-content: center;
     align-items: center;
}

#modul{
    /* background-image: url('./imagenes/rough-black-lines-textured-background.jpg'); */
    background-size: cover;
    padding-top: 8px;
    min-height: 100px;
    color: #0a0a0a;


    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


     /* Sizing */
     width: 100vw;
     /* height: 100vh; */
     
     /* Flexbox stuff */

     justify-content: center;
     align-items: center;
}

#hero p{
    color: #ffffff;
}



    @-webkit-keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            transform: translatey(0);
        }
    }
    @keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            transform: translatey(0);
        }
    }
    .in-down {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
    }


.hero_btn {
    padding: 15px 26px;
    min-width: 160px;
    /* border-radius: 2px; */
    display: inline-block;


    /* background-color:rgba(147, 112, 219, 0.8);; */
background-color: rgba(0, 0, 0, .05);
    color: #ffe1e1;
    border: 2px solid#A9A9A9;
    font-size: 16px;
    margin-left: 6px;
    
    /* Positioning and sizing */
    /* display: block; */
    width: 200px;
    
    /* Padding and margins */
    /* padding: 1em; */
    margin-top: 50px;
    /* margin-left: auto; */
    margin-right: auto;
    
    /* Text styles */
    /* color: white; */
    text-decoration: none;
    /* font-size: 1.5em; */
    
    /* Border styles */
    /* border: 3px solid white; */
    border-radius: 20px;
    
    /* Background styles */
    /* background-color: rgba(147, 112, 219, 0.8); */
    transition: all 0.3s ease-in-out;

}



.content-center{
    max-width: 800px;
    margin: 0 auto 60px auto;
    text-align: center;
}

.portfolio-container{
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 2px;
}

.portfolio-container img{
    transition: all 0.85s;
    width:660px; 
    height: 400px;
}

.portfolio-container:hover img{
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.portfolio-details{
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 9000;
}

.portfolio-details h2{
    margin-block: 0px;
}

.portfolio-details a h2, .portfolio-details a p{
    color: #ffffff;
}

.member-container{
    position: relative;
    margin: 10px 0;
}

.member-details{
    position: absolute;
    color: #ffffff;
    bottom: 0px;
    left: 20px;
}

.member-details span{
    font-size: 14px;
}

.member-details ion-icon{
    padding: 0 5px;
}

.pricing-container{
    margin: 15px 0;
}

.plans{
    padding: 50px 80px;
    border-radius: 2px;
    min-width: 450px;
}

.plan-ligth{
    background-color: #ffffff;
    border: 2px solid #1a1a1a;
}

.plan-dark{
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    color: #ffffff;
}

.plans h2{
    font-size: 70px;
    font-weight: 600;
}

.plans span{
    font-size: 20px;
}

.plans ul{
    list-style-type: square;
}

.carousel{
    min-height: 240px;
    padding: 40px;
    border-radius: 2px;
    text-align: center;
}

.carousel p{
    font-size: 26px;
    font-weight: 400;
    color: #1a1a1a;
}

.rating ion-icon{
    color: #ffb944;
    font-size: 26px;
}

.carousel-indicatiors li{
    background-color: #1a1a1a;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin: 0 8px;
}

.carousel-control-next, .carousel-control-prev{
    color: #ffffff;
    opacity: 1;
    width: 5%;
}

.carousel-container{
    max-width: 800px;
    margin: 0 auto;
}

.control-button{
    background-color: #1a1a1a;
    opacity: 1;
    width: 50px;
    height: 50px;
    padding: 10px;
    border: 1px solid #1a1a1a;
    border-radius: 50px;
    font-size: 20px;
}

.testimonial-user{
    max-width: 240px;
    margin: 0 auto;
    text-align: left;
}

.testimonial-user h6{
    margin-bottom: 0px;
}

.testimonial-user span{
    font-size: 12px;
}

.testimonial-user img{
    border-radius: 50px;
    max-width: 50px;
    text-align: center;
}

#footer{
    padding: 80px 0;
    text-align: center;
    color: #ffffff;
}

#footer a{
    color: #ffffff;
}

#footer a:hover{
    color: #ffffff;
}

#footer .list-inline .list-inline-item{
    padding: 10px 15px;
}
@media (max-width: 575.98px) { 
    h1{ font-size: 40px; line-height: normal;}
    .portfolio-details h2{font-size: 32px;}
    .plans{ min-width: unset; padding: 40px;}
    .carousel-item p{font-size: 20px;}
    .footer-menu{ display: block !important; padding: 20px 0 !important; font-size: 20px;}
 }


/* @media (min-width: 576px) and (max-width: 767.98px) { ... } */


@media (min-width: 768px) and (max-width: 991.98px) { 
    .portfolio-details{ font-size: 30px;}
    .plans{ min-width: unset; padding: 40px 20px;}
 }


/* @media (min-width: 992px) and (max-width: 1199.98px) { ... } */


/* @media (min-width: 1200px) { ... } */



/** fadeInLeft **/

@-webkit-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        transform: translatex(0);
    }
}
.in-left {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-duration:1s;
    animation-delay: 1s;
}

/* navbar scroll */
.navbar{
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    min-height: 50px;

}

/* .texto{
    color:
} */

    


  
 
/* 
========================================= 
========================================= 

Bootstrapious Boilerplate Template

========================================= 
========================================= */
/*
*
* =====================
* GENERAL
* =====================
*
*/
[data-animate] {
  visibility: hidden;
}

.animated {
  visibility: visible;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

body {
  color: #555;
}

p {
  color: #555;
}

section {
  padding: 60px 0;
  position: relative;
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 0.9rem;
}

.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.text-300 {
  font-weight: 300 !important;
}

.text-400 {
  font-weight: 400 !important;
}

.text-500 {
  font-weight: 500 !important;
}

.title {
  display: inline-block;
  font-size: 2.9rem;
  font-weight: 100;
  padding: 10px 0;
  margin-bottom: 44px;
  text-align: center;
  border-bottom: solid 1px #ccc;
  border-top: solid 1px #ccc;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  color: #555;
  margin-top: 20px;
  letter-spacing: 0;
}

.title span {
  font-size: 2.5rem;
}

.bg-gradient {
  background: linear-gradient(to bottom, #9055A2, #a774b6) !important;
  color: #fff;
}

.bg-gradient * {
  color: inherit;
}

.bg-gray {
  background: #f5f5f5;
}

.dark-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
  z-index: -1;
}

.parallax {
  background-size: cover !important;
  transition: all 0.1s !important;
}

/*
*
* =====================
* NAVBAR
* =====================
*
*/
nav.navbar {
  padding-top: 0;
  padding-bottom: 0;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
}

@media (max-width: 991.98px) {
  nav.navbar {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

nav.navbar .navbar-brand {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
}

nav.navbar a.nav-link {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0 5px;
  color: #777;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 4px solid transparent;
  transition: all 0.3s;
  font-size: 0.875rem;
}

@media (max-width: 991.98px) {
  nav.navbar a.nav-link {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border: none;
  }
}

nav.navbar a.nav-link.active, nav.navbar a.nav-link:hover {
  color: #333;
  border-top-color: #ffffff;
}

@media (max-width: 1199.98px) {
  nav.navbar a.nav-link.active, nav.navbar a.nav-link:hover {
    color: #9055A2;
  }
}

/*
*
* =====================
* INTRO
* =====================
*
*/
.intro-section {
  padding-top: 150px;
  color: #fff;
}

.intro-section .logo, .intro-section p {
  margin-bottom: 120px;
}

@media (min-width: 992px) {
  .intro-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}

/*
*
* =====================
* ABOUT
* =====================
*
*/
.about-section .skill-item {
  margin-bottom: 20px;
}

.about-section .progress-title {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.about-section .progress {
  height: 30px;
  border-radius: 0;
}

.about-section .progress .progress-bar {
  background: #9055A2;
}

.about-section .progress .progress-bar-skill1 {
  background: #bc95c8;
}

.about-section .progress .progress-bar-skill2 {
  background: #b185bf;
}

.about-section .progress .progress-bar-skill3 {
  background: #a774b6;
}

.about-section .progress .progress-bar-skill4 {
  background: #9c63ad;
}

/*
*
* =====================
* SERVICES
* =====================
*
*/
.services-section hr {
  border-color: #fff;
}

.services-section .icon {
  font-size: 3.5rem;
  text-shadow: 5px 5px 0 #faecff;
}

.services-section p.description {
  font-size: 0.9rem;
}

/*
*
* =====================
* TESTIMONIALS
* =====================
*
*/
.testimonials {
  padding: 0;
  margin-bottom: 40px;
}

.testimonials .item {
  list-style-type: none;
  margin: 0 5px;
  background: #fff;
  padding-bottom: 60px;
}

.testimonials .item .testimonial {
  position: relative;
  padding: 20px;
}

.testimonials .item .testimonial::after {
  display: block;
  clear: both;
  content: "";
}

.testimonials .item .testimonial .text {
  color: #999;
  margin-bottom: 40px;
}

.testimonials .item .testimonial .text p {
  color: inherit;
}

.testimonials .item .testimonial .bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  height: 50px;
}

.testimonials .item .testimonial .bottom .icon {
  color: #9055A2;
  font-size: 30px;
  float: left;
  width: 20%;
}

.testimonials .item .testimonial .name-picture {
  float: right;
  width: 80%;
  text-align: right;
}

.testimonials .item .testimonial .name-picture h5 {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #777;
}

.testimonials .item .testimonial .name-picture p {
  color: #6c757d;
  margin: 0;
  font-size: 0.875rem;
}

.testimonials .item .testimonial .name-picture img {
  float: right;
  width: 60px;
  border-radius: 30px;
  margin-left: 10px;
}

.equalize-height .owl-stage {
  display: flex;
  align-items: stretch;
}

.equalize-height .owl-stage .owl-item .item {
  height: 100%;
}

.equalize-height .owl-stage .owl-item .full-height {
  height: 100%;
}

.full-height {
  height: 100% !important;
}

/*
*
* =====================
* SHOWCASE
* =====================
*
*/
.statistics-section {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
}

.statistics-section .icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  color: #fff;
  line-height: 50px;
  border-radius: 25px;
  border: solid 1px #fff;
}

.statistics-section h5 {
  font-family: "Roboto", sans-serif;
}

.statistics-section .counter {
  font-weight: 300;
  font-size: 2.5rem;
}

/*
*
* =====================
* REFERENCES
* =====================
*
*/
#filter {
  text-align: center;
  margin: 20px 0;
  padding: 0;
}

#filter li {
  display: inline-block;
  list-style-type: none;
  font-family: "Roboto Slab", serif;
  margin-bottom: 30px;
  border-top: solid 1px #9055A2;
  border-bottom: solid 1px #9055A2;
  margin: 0 10px 20px;
  transition: all 0.3s;
}

#filter li a {
  display: inline-block;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #9055A2;
  outline: none;
  transition: all 0.3s;
}

#filter li.active, #filter li:hover {
  border: none;
}

#filter li.active a, #filter li:hover a {
  color: #fff;
  text-decoration: none;
  background: #9055A2;
}

.reference {
  margin-bottom: 30px;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .reference {
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.reference a {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}

.reference h3 {
  font-weight: 400;
}

.reference img {
  transition: all 0.3s;
}

.reference .overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  opacity: 0;
  transition: all 0.4s;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  padding: 15px;
}

.reference .overlay p {
  color: #fff;
}

.reference .reference-title {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.reference:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.reference:hover .overlay {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

#detail {
  display: none;
}

#detail .btn {
  margin: 10px;
}

#detail .close {
  position: absolute;
  right: 0;
  z-index: 999;
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  font-size: 3rem;
  opacity: 0.3;
  transition: all 0.3s;
}

#detail .close:hover {
  opacity: 1;
}

#detail-slider {
  margin-top: 50px;
}

/*
*
* =====================
* CUSTOEMRS
* =====================
*
*/
.customers-section img {
  padding: 10px;
  max-width: 80px;
}

/*
*
* =====================
* CONTACT
* =====================
*
*/
.form-control {
  margin-bottom: 20px;
}

/*
*
* =====================
* MAP
* =====================
*
*/
#map {
  height: 400px;
}

/*
*
* =====================
* FOOTER
* =====================
*
*/
footer.main-footer {
  padding: 60px 0;
  background: #222;
}

footer.main-footer p.social a {
  margin-left: 10px;
  color: #fff;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 25px;
  line-height: 45px;
  font-size: 1.3rem;
  vertical-align: middle;
  text-align: center;
  transition: all 0.3s;
}

footer.main-footer p.social a.facebook {
  background-color: #4460ae;
}

footer.main-footer p.social a.gplus {
  background-color: #c21f25;
}

footer.main-footer p.social a.instagram {
  background-color: #cd4378;
}

footer.main-footer p.social a.email {
  background-color: #4a7f45;
}

footer.main-footer p {
  margin: 0;
  color: #eee;
  font-family: "Roboto Slab", serif;
  font-size: 0.9rem;
}

footer.main-footer p.template-bootstrapious {
  margin-top: 20px;
  text-align: center;
  color: #eee;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
}

footer.main-footer p.template-bootstrapious a {
  color: #999;
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
  position: fixed;
  top: 120px;
  left: 0px;
  border-radius: 0;
  z-index: 2;
}

#style-switch {
  width: 300px;
  padding: 20px;
  position: fixed;
  top: 160px;
  left: 0;
  background: #fff;
  border: solid 1px #ced4da;
  z-index: 2000;
}

#style-switch h4 {
  color: #495057;
}

/* =========================================
   THEMING OF BOOTSTRAP COMPONENTS
   ========================================= */
/*
 * 1. NAVBAR
 */
.navbar {
  padding: 0.5rem 1rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0rem;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
  font-weight: 400;
  border: 1px solid transparent;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 0.45rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus, .btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

.btn-link {
  font-weight: 400;
  color: #9055A2;
}

.btn-link:hover {
  color: #633b70;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-primary {
  color: #fff;
  background-color: #9055A2;
  border-color: #9055A2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #7a4889;
  border-color: #724381;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #9055A2;
  border-color: #9055A2;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #724381;
  border-color: #6b3f78;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #9055A2;
  background-color: transparent;
  background-image: none;
  border-color: #9055A2;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #9055A2;
  border-color: #9055A2;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #9055A2;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #9055A2;
  border-color: #9055A2;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-light:hover {
  color: #212529;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0rem;
}

/*
 * 3. TYPE
 */
body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

a {
  color: #9055A2;
  text-decoration: none;
}

a:hover, a:focus {
  color: #ffffff;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 3.6rem;
}

h2,
.h2 {
  font-size: 2.15rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.4375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  border-left: 5px solid #9055A2;
}

.blockquote-footer {
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.text-primary {
  color: #9055A2 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #724381 !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #9055A2;
  border-color: #9055A2;
}

.page-item.disabled .page-link {
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: #9055A2;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover, .page-link:focus {
  color: #633b70;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/*
* 5. UTILITIES
*/
.bg-primary {
  background-color: #9055A2 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #724381 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.border-primary {
  border-color: #9055A2 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.text-primary {
  color: #9055A2 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #724381 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.badge-primary {
  color: #fff;
  background-color: #9055A2;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #724381;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

/*
  * 6. FORMS
  */
.form-control {
  padding: 0.45rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c6a6d0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(144, 85, 162, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
}

.form-control::placeholder {
  color: #6c757d;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/*
* 7.CODE
*/
code {
  font-size: 87.5%;
  color: #e83e8c;
}

/*
* 8. NAV
*/
.nav-link {
  padding: 0.5rem 1rem;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #9055A2;
}

/*
* 9. CARD
*/
.card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header-transparent {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: none;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fa;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
}

.card-img-overlay-opacity {
  background: rgba(0, 0, 0, 0.2);
}

.card-img {
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}

h3.encabezado{
    font-family: 'Crimson Text', serif;
    font-weight: 700;
}

h5.labelb{
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    text-align: left;
}

h6.descripcion{
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    text-decoration: underline;
}

table.table-css  {
    --border: 1px solid black;
    border-radius: 10px;
    border-spacing: 0;
 border: var(--border);
 overflow: hidden;
 border-right: var(--border);
 border-bottom: var(--border);
  }
table.table-css > thead > tr > th {
    background-color: #6c79e0;
    color: white;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    font-size: 14px;
}
table.table-bordered > tbody > tr > td{
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    font-size: 14px;
}

