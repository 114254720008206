

    .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    /*left:-100%;*/
    left:10rem; /* 10rem is the min-width of dropdown-menu */
    margin-top:-6px;
   
}


.dropdown-menu {
  
    font-size: 1.3rem;
    
}



body{
    font-family: 'Koh Santepheap', cursive;
}

h1{

    font-size: 30px;
    font-weight: 600;
    line-height: 80px;
}

h3{
    color: #080808;
    font-family: 'Koh Santepheap', cursive;
    font-size: 8.4rem;
    line-height: 1.071;
    max-width: 900px;
    margin-top: 0;
    margin-bottom: .6rem;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

h2{
    font-size: 48px;
    margin-bottom: 30px;
}

h5{
    margin-bottom: 0px;
}

p{
    font-size: 18px;
    color: #999999;
    line-height: 1.8;
    margin-bottom: 0;
}

a:hover{
    text-decoration: none;
}

a i{
    font-size: 22px;
    /* color: #ffffff; */
    color: #000000;
}

section{
    padding: 120px 0;
}

.bg-light-grey{
    background-color: #f5f5f5;
}

.bg-dark{
    background-color: #1a1a1a !important;
}

.topmargin-xs{
    margin-top: 15px !important;
}

.topmargin-sm{
    margin-top: 30px !important;
}

.topmargin-lg{
    margin-top: 60px !important;
}

.topmargin-lg2{
    margin-top: 5px !important;
}

.btn{
    font-size: 14px;
    padding: 15px 26px;
    /* min-width: 160px; */
    border-radius: 2px;
    display: inline-block;
}

.btn-ligth{
    background-color: #ffffff;
    color: #1a1a1a;
    border: 2px solid #ffffff;
}

.hero_btn ion-icon{
    font-size: 14px;
    margin-left: 6px;
}

.btn-dark{
    background-color: #1a1a1a;
    color: #ffffff;
    border: 2px solid #1a1a1a;
}

.btn-transparent{
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;
}

.btn-transparent:hover{
    color: #ffffff;
}

.full-width{
    width: 100%;
}

.form-control{
   
}

.form-control:focus{
    border: 1px solid #1a1a1a;
    outline: none;
    box-shadow: none;
}
.text-dark{
    color: #1a1a1a;
}

.divider{
    border-bottom: 2px solid#D3D3D3;
}



.nav-link{
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
}

.nav-link2{
    color: #ffffff;
}

.nav-link:hover{
    color: #1a1a1a;
}

/* .header {
    background: url(../img/bg-header.jpeg) no-repeat center;
    background-size: cover;
    min-height: 100vh;
  }*/
.header .navbar {
/* background:rgba(0,0,0,0.3) !important  ; */
/* background:rgb(0 0 0 / 82%) !important  ; */
background-color: #00122fe0!important;
font-family: 'Nanum Myeongjo', serif;
  } 

.header .scroll {
background:black !important ;
} 
 
.logo-brand{
    min-width: 100px;
    max-width: 120px;
    background-image: black;
}

.img-perfil{
    width:350px; /* you can use % */
    height: 350px;
}

.sombreado{
    text-shadow: black 0.1em 0.1em 0.2em
}
#hero{
    /* background-image: url('./imagenes/hero_1.jpg'); */
    background-size: cover;
    padding-top: 90px;
    min-height: 700px;
    color: #ffffff;text-shadow: black 0.1em 0.1em 0.2em;



    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


     /* Sizing */
     width: 100vw;
     height: 100vh;
     
     /* Flexbox stuff */
     display: flex;
     justify-content: center;
     align-items: center;
}

#modul{
    /* background-image: url('./imagenes/rough-black-lines-textured-background.jpg'); */
    background-size: cover;
    padding-top: 8px;
    min-height: 100px;
    color: #0a0a0a;


    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


     /* Sizing */
     width: 100vw;
     /* height: 100vh; */
     
     /* Flexbox stuff */

     justify-content: center;
     align-items: center;
}

#hero p{
    color: #ffffff;
}



    @-webkit-keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            -moz-transform: translatey(-10px);
            -o-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            -moz-transform: translatey(0);
            -o-transform: translatey(0);
            transform: translatey(0);
        }
    }
    @-moz-keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            -moz-transform: translatey(-10px);
            -o-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            -moz-transform: translatey(0);
            -o-transform: translatey(0);
            transform: translatey(0);
        }
    }
    @keyframes fadeInDown {
        from {
            opacity:0;
            -webkit-transform: translatey(-10px);
            -moz-transform: translatey(-10px);
            -o-transform: translatey(-10px);
            transform: translatey(-10px);
        }
        to {
            opacity:1;
            -webkit-transform: translatey(0);
            -moz-transform: translatey(0);
            -o-transform: translatey(0);
            transform: translatey(0);
        }
    }
    .in-down {
        -webkit-animation-name: fadeInDown;
        -moz-animation-name: fadeInDown;
        -o-animation-name: fadeInDown;
        animation-name: fadeInDown;
        -webkit-animation-fill-mode: both;
        -moz-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        -o-animation-duration: 1s;
        animation-duration: 1s;
    }


.hero_btn {
    padding: 15px 26px;
    min-width: 160px;
    /* border-radius: 2px; */
    display: inline-block;


    /* background-color:rgba(147, 112, 219, 0.8);; */
background-color: rgba(0, 0, 0, .05);
    color: #ffe1e1;
    border: 2px solid#A9A9A9;
    font-size: 16px;
    margin-left: 6px;
    
    /* Positioning and sizing */
    /* display: block; */
    width: 200px;
    
    /* Padding and margins */
    /* padding: 1em; */
    margin-top: 50px;
    /* margin-left: auto; */
    margin-right: auto;
    
    /* Text styles */
    /* color: white; */
    text-decoration: none;
    /* font-size: 1.5em; */
    
    /* Border styles */
    /* border: 3px solid white; */
    border-radius: 20px;
    
    /* Background styles */
    /* background-color: rgba(147, 112, 219, 0.8); */
    transition: all 0.3s ease-in-out;

}



.content-center{
    max-width: 800px;
    margin: 0 auto 60px auto;
    text-align: center;
}

.portfolio-container{
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 2px;
}

.portfolio-container img{
    -moz-transition: all 0.85s;
    -webkit-transition: all 0.85s;
    transition: all 0.85s;
    width:660px; 
    height: 400px;
}

.portfolio-container:hover img{
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
.portfolio-details{
    position: absolute;
    bottom: 25px;
    left: 25px;
    z-index: 9000;
}

.portfolio-details h2{
    margin-block: 0px;
}

.portfolio-details a h2, .portfolio-details a p{
    color: #ffffff;
}

.member-container{
    position: relative;
    margin: 10px 0;
}

.member-details{
    position: absolute;
    color: #ffffff;
    bottom: 0px;
    left: 20px;
}

.member-details span{
    font-size: 14px;
}

.member-details ion-icon{
    padding: 0 5px;
}

.pricing-container{
    margin: 15px 0;
}

.plans{
    padding: 50px 80px;
    border-radius: 2px;
    min-width: 450px;
}

.plan-ligth{
    background-color: #ffffff;
    border: 2px solid #1a1a1a;
}

.plan-dark{
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
    color: #ffffff;
}

.plans h2{
    font-size: 70px;
    font-weight: 600;
}

.plans span{
    font-size: 20px;
}

.plans ul{
    list-style-type: square;
}

.carousel{
    min-height: 240px;
    padding: 40px;
    border-radius: 2px;
    text-align: center;
}

.carousel p{
    font-size: 26px;
    font-weight: 400;
    color: #1a1a1a;
}

.rating ion-icon{
    color: #ffb944;
    font-size: 26px;
}

.carousel-indicatiors li{
    background-color: #1a1a1a;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin: 0 8px;
}

.carousel-control-next, .carousel-control-prev{
    color: #ffffff;
    opacity: 1;
    width: 5%;
}

.carousel-container{
    max-width: 800px;
    margin: 0 auto;
}

.control-button{
    background-color: #1a1a1a;
    opacity: 1;
    width: 50px;
    height: 50px;
    padding: 10px;
    border: 1px solid #1a1a1a;
    border-radius: 50px;
    font-size: 20px;
}

.testimonial-user{
    max-width: 240px;
    margin: 0 auto;
    text-align: left;
}

.testimonial-user h6{
    margin-bottom: 0px;
}

.testimonial-user span{
    font-size: 12px;
}

.testimonial-user img{
    border-radius: 50px;
    max-width: 50px;
    text-align: center;
}

#footer{
    padding: 80px 0;
    text-align: center;
    color: #ffffff;
}

#footer a{
    color: #ffffff;
}

#footer a:hover{
    color: #ffffff;
}

#footer .list-inline .list-inline-item{
    padding: 10px 15px;
}
@media (max-width: 575.98px) { 
    h1{ font-size: 40px; line-height: normal;}
    .portfolio-details h2{font-size: 32px;}
    .plans{ min-width: unset; padding: 40px;}
    .carousel-item p{font-size: 20px;}
    .footer-menu{ display: block !important; padding: 20px 0 !important; font-size: 20px;}
 }


/* @media (min-width: 576px) and (max-width: 767.98px) { ... } */


@media (min-width: 768px) and (max-width: 991.98px) { 
    .portfolio-details{ font-size: 30px;}
    .plans{ min-width: unset; padding: 40px 20px;}
 }


/* @media (min-width: 992px) and (max-width: 1199.98px) { ... } */


/* @media (min-width: 1200px) { ... } */



/** fadeInLeft **/

@-webkit-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-100px);
        -moz-transform: translatex(-100px);
        -o-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
.in-left {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-duration:1s;
    animation-delay: 1s;
}

/* navbar scroll */
.navbar{
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    min-height: 50px;

}

/* .texto{
    color:
} */

    


  
 