@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&display=swap');

h3.encabezado{
    font-family: 'Crimson Text', serif;
    font-weight: 700;
}

h5.labelb{
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    text-align: left;
}

h6.descripcion{
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    text-decoration: underline;
}

table.table-css  {
    --border: 1px solid black;
    border-radius: 10px;
    border-spacing: 0;
 border: var(--border);
 overflow: hidden;
 border-right: var(--border);
 border-bottom: var(--border);
  }
table.table-css > thead > tr > th {
    background-color: #6c79e0;
    color: white;
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    font-size: 14px;
}
table.table-bordered > tbody > tr > td{
    font-family: 'Crimson Text', serif;
    font-weight: 600;
    font-size: 14px;
}
